// Huge: FontAwesome completo, non ha bisogno di altri import ma è sconsigliato per le dimensioni
//         yarn add @fortawesome/fontawesome-pro@next
//
// import '@fortawesome/fontawesome-pro/js/all';


// Medium: import delle singole varianti, quindi dimensioni ridotte rispetto alla versione completa,
//         ma comunque abbastanza importanti in quanto ogni variante ha svariate centinaia di icone;
//         l'ultimo import (js/fontawesome) è necessario per il buon funzionamento
//           yarn add @fortawesome/fontawesome-pro@next
//
// import '@fortawesome/fontawesome-pro/js/solid';
// import '@fortawesome/fontawesome-pro/js/regular';
// import '@fortawesome/fontawesome-pro/js/brands';
// import '@fortawesome/fontawesome-pro/js/light';
// import '@fortawesome/fontawesome-pro/js/duotone';
// import '@fortawesome/fontawesome-pro/js/fontawesome';


// Small: include solo le icone utilizzate, quindi lo spazio è ridotto al minimo
//        eseguire l'apposito task per aggiornare la lista degli import tra i commenti BEGIN ed END sottostanti,
//        da utilizzare ogni volta che si aggiunge o rimuove un'icona:
//          rails fontawesome:repack
//        Per utilizzarlo sono necessari i seguenti pacchetti:
//          yarn add @fortawesome/pro-{solid,regular,light,duotone}-svg-icons@next @fortawesome/fontawesome-svg-core@next
//
import { library, dom } from '@fortawesome/fontawesome-svg-core';
// BEGIN fontawesome:repack - Attenzione: il contenuto tra questi commenti viene sovrascritto dall'esecuzione del task
import {
  faCheckCircle as fadCheckCircle,
  faCog as fadCog,
  faExclamationTriangle as fadExclamationTriangle,
  faTimesCircle as fadTimesCircle,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faAngleDoubleLeft as fasAngleDoubleLeft,
  faAngleDoubleRight as fasAngleDoubleRight,
  faArrowDown as fasArrowDown,
  faArrowUp as fasArrowUp,
  faBan as fasBan,
  faCameraRetro as fasCameraRetro,
  faCheck as fasCheck,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faEdit as fasEdit,
  faExclamationTriangle as fasExclamationTriangle,
  faFileAlt as fasFileAlt,
  faFolder as fasFolder,
  faGhost as fasGhost,
  faIdCard as fasIdCard,
  faKey as fasKey,
  faList as fasList,
  faLockAlt as fasLockAlt,
  faMapMarkerAlt as fasMapMarkerAlt,
  faMoneyCheckEdit as fasMoneyCheckEdit,
  faPaperPlane as fasPaperPlane,
  faSave as fasSave,
  faSignInAlt as fasSignInAlt,
  faStar as fasStar,
  faTimes as fasTimes,
  faTrash as fasTrash,
  faTrashAlt as fasTrashAlt,
  faUndo as fasUndo,
  faUserCircle as fasUserCircle,
  faWrench as fasWrench,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faAt as farAt,
  faBagsShopping as farBagsShopping,
  faBooks as farBooks,
  faBoxCheck as farBoxCheck,
  faCalendar as farCalendar,
  faCalendarCheck as farCalendarCheck,
  faCartPlus as farCartPlus,
  faClock as farClock,
  faCloudDownloadAlt as farCloudDownloadAlt,
  faCommentAltDots as farCommentAltDots,
  faCommentCheck as farCommentCheck,
  faCommentExclamation as farCommentExclamation,
  faCommentPlus as farCommentPlus,
  faCommentsAlt as farCommentsAlt,
  faDollyFlatbed as farDollyFlatbed,
  faDollyFlatbedAlt as farDollyFlatbedAlt,
  faEnvelope as farEnvelope,
  faFilePdf as farFilePdf,
  faHandHoldingBox as farHandHoldingBox,
  faHardHat as farHardHat,
  faMailBulk as farMailBulk,
  faMapMarkerQuestion as farMapMarkerQuestion,
  faMegaphone as farMegaphone,
  faMoneyCheck as farMoneyCheck,
  faNewspaper as farNewspaper,
  faPaperPlane as farPaperPlane,
  faPaperclip as farPaperclip,
  faPencil as farPencil,
  faPersonDolly as farPersonDolly,
  faPersonDollyEmpty as farPersonDollyEmpty,
  faQuestionSquare as farQuestionSquare,
  faShareAlt as farShareAlt,
  faShippingFast as farShippingFast,
  faShoppingCart as farShoppingCart,
  faStar as farStar,
  faThumbsUp as farThumbsUp,
  faTimes as farTimes,
  faTimesCircle as farTimesCircle,
  faTrash as farTrash,
  faTrashAlt as farTrashAlt,
  faUser as farUser,
  faUserPlus as farUserPlus,
  faUsers as farUsers,
  faVideo as farVideo,
} from '@fortawesome/pro-regular-svg-icons';
library.add(fadCheckCircle, fadCog, fadExclamationTriangle, fadTimesCircle, farAt, farBagsShopping, farBooks, farBoxCheck, farCalendar, farCalendarCheck, farCartPlus, farClock, farCloudDownloadAlt, farCommentAltDots, farCommentCheck, farCommentExclamation, farCommentPlus, farCommentsAlt, farDollyFlatbed, farDollyFlatbedAlt, farEnvelope, farFilePdf, farHandHoldingBox, farHardHat, farMailBulk, farMapMarkerQuestion, farMegaphone, farMoneyCheck, farNewspaper, farPaperPlane, farPaperclip, farPencil, farPersonDolly, farPersonDollyEmpty, farQuestionSquare, farShareAlt, farShippingFast, farShoppingCart, farStar, farThumbsUp, farTimes, farTimesCircle, farTrash, farTrashAlt, farUser, farUserPlus, farUsers, farVideo, fasAngleDoubleLeft, fasAngleDoubleRight, fasArrowDown, fasArrowUp, fasBan, fasCameraRetro, fasCheck, fasChevronLeft, fasChevronRight, fasEdit, fasExclamationTriangle, fasFileAlt, fasFolder, fasGhost, fasIdCard, fasKey, fasList, fasLockAlt, fasMapMarkerAlt, fasMoneyCheckEdit, fasPaperPlane, fasSave, fasSignInAlt, fasStar, fasTimes, fasTrash, fasTrashAlt, fasUndo, fasUserCircle, fasWrench);
// END fontawesome:repack - Attenzione: il contenuto tra questi commenti viene sovrascritto dall'esecuzione del task
dom.watch();

// Fix fullcalendar bug: https://github.com/fullcalendar/fullcalendar/issues/5544#issuecomment-658406050
import { config } from '@fortawesome/fontawesome-svg-core';
config.autoReplaceSvg = 'nest';
